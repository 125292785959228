<template>
  <navigation-drawer></navigation-drawer>

  <v-main>
    <v-container>
      <v-row>
        <v-col cols="12">
          <c-card color="gray">
            <v-card-title> Add your new image </v-card-title>
            <!-- <v-text-field
              v-model="product_title"
              label="Product Title"
              :rules="form_validation_rules"
            ></v-text-field>
            <v-select
              v-model="product_category"
              :items="product_category_choices"
              label="Category"
              :rules="form_validation_rules"
            ></v-select> -->
            <v-file-input
              v-model="product_image"
              accept="image/*"
              prepend-icon="mdi-camera"
            >
            </v-file-input>

            <v-btn type="submit" color="primary" @click="createProduct()"
              >Submit</v-btn
            >
          </c-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import APIMixin from "@/mixins/APIMixin";
import axios from "axios";
import NavigationDrawer from "../components/NavigationDrawer.vue";
import router from "@/router";

export default {
  name: "create-product",
  props: {
    msg: String,
  },
  data() {
    return {
      product_title: "",
      product_category_choices: ["Fashion", "Jewelry", "Sports"],
      product_category: "",
      product_image: null,
      preview_image_url: "",
      form_validation_rules: [
        (value) => {
          if (value) return true;

          return "This field cannot be empty.";
        },
      ],
    };
  },
  mixins: [APIMixin],
  created() {},
  methods: {
    createProduct: function () {
      if (this.product_image) {
        let formData = new FormData();
        console.log(this.product_image[0]);

        formData.append("image", this.product_image[0]);
        formData.append("title", this.product_title);
        formData.append("category", this.product_category);
        console.log(formData);
        axios
          .post(this.api.product_create, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            console.log("Success!");
            console.log({ response });
            router.push(this.pages.product_list);
          })
          .catch((error) => {
            console.log({ error });
          });
      } else {
        console.log("there are no files.");
      }
    },
  },
  components: { NavigationDrawer },
};
</script>
