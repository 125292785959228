<template>
  <v-btn
    icon="mdi-menu"
    color="indigo-darken-4"
    size="x-small"
    @click.stop="drawer_model = !drawer_model"
    style="position: fixed; top: 12px; left: 12px; z-index: 1000"
    v-if="!drawer_model"
  ></v-btn>

  <v-navigation-drawer
    color="indigo-darken-4"
    v-model="drawer_model"
    rail
    expand-on-hover
    app
  >
    <v-sheet color="indigo-darken-4">
      <v-list>
        <v-list-item
          prepend-icon="mdi-home"
          title="Home"
          @click="goToPage('/products')"
        >
        </v-list-item>
        <v-list-item
          prepend-icon="mdi-account"
          title="Profile"
          @click="goToPage('/profile')"
        >
          <v-list-item-subtitle>{{ user_info.email }}</v-list-item-subtitle>
          <v-list-item-subtitle
            >{{ user_info.credit }} Credits</v-list-item-subtitle
          >
        </v-list-item>
      </v-list>

      <v-divider></v-divider>

      <v-list density="compact" nav>
        <v-list-item
          prepend-icon="mdi-logout"
          title="Sign Out"
          @click="logoutUser()"
        ></v-list-item>
      </v-list>
    </v-sheet>
  </v-navigation-drawer>
</template>

<script>
// import APIMixin from "@/mixins/APIMixin"
import axios from "axios";
import router from "@/router";
import APIMixin from "@/mixins/APIMixin";
import authenticationMixin from "@/mixins/authenticationMixin";

export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
  mixins: [APIMixin, authenticationMixin],

  data: () => ({
    drawer: false,
    user_profile_menu: false,
    links: [
      ["mdi-inbox-arrow-down", "Product List", "/products"],
      ["mdi-inbox-arrow-down", "Home Page", "/home"],
      ["mdi-inbox-arrow-down", "Canvas", "/canvas"],
      // ["mdi-send", "Send", "/login"],
    ],
    user_info: {},
    drawer_model: false,
    rail: true,
  }),
  created() {
    this.getUserInformation();
  },
  methods: {
    goToPage: function (destination) {
      router.push(destination);
    },
    logoutUser: function () {
      this.logout();
      router.push("/login");
    },
    getUserInformation: function () {
      axios
        .get(this.api.user_info)
        .then((response) => {
          this.user_info = response.data;
        })
        .catch((error) => {
          console.log(error);
          console.log("error happended");
        });
    },
  },
};
</script>
