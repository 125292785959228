<template>
  <v-row class="d-flex justify-center align-center">
    <v-col cols="9" sm="12" md="12" lg="10">
      <v-card>
        <v-card-title
          >Add more credit! You don't have enough credit to do this
          action!</v-card-title
        >
        <v-card-text>
          <subscription> </subscription>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import Subscription from "../components/Subscription.vue";
export default {
  name: "NonsufficientCredit",
  props: {
    msg: String,
  },
  components: { Subscription },
};
</script>
