<template>
  <ApplicationBar></ApplicationBar>
  <v-main>
    <v-container>
      <v-dialog v-model="ui.subscription_cancel_dialog.show" max-width="500">
        <v-card>
          <v-toolbar color="red-lighten-1" flat>
            <v-card-title> We are sad to see you go! </v-card-title>
          </v-toolbar>
          <v-card-text>
            <p>
              Cancelling your subscription means you will lose access to all
              premium features and content but you still can use all of the
              credit that you have.
            </p>
          </v-card-text>
          <v-card-text>
            <v-alert
              density="compact"
              color="info"
              type="info"
              text="Please note that you will no longer be billed after cancelling
            your subscription, and your account will revert to the free plan
            at the end of the current billing cycle."
            ></v-alert>
          </v-card-text>
          <v-card-text>
            <v-alert
              density="compact"
              type="warning"
              color="warning"
              text="Are you sure you want to cancel your subscription?"
            ></v-alert>
          </v-card-text>
          <v-card-text>
            If you want to cancel your subscription write "cancel my
            subscription"
          </v-card-text>
          <v-card-text>
            <v-text-field
              v-model="
                ui.subscription_cancel_dialog.cancel_my_subscription_textfield
              "
              placeholder="cancel my subscription"
            ></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-btn
              :disabled="
                ui.subscription_cancel_dialog
                  .cancel_my_subscription_textfield != 'cancel my subscription'
              "
              :loading="
                ui.subscription_cancel_dialog
                  .loading_cancel_my_subscription_button
              "
              color="red-darken-4"
              @click="CancelUserSubscription()"
              block
              flat
              >Cancel My Subscription</v-btn
            >
          </v-card-actions>
          <v-card-actions>
            <v-btn
              @click="ui.subscription_cancel_dialog.show = false"
              block
              color="success"
              variant="flat"
              >Continue Using Shimmers</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-row>
        <v-col cols="12">
          <v-card>
            <v-card>
              <v-card-title>User Information</v-card-title>
              <v-card-text>
                <v-list>
                  <v-list-item>
                    <v-list-item-title>Email:</v-list-item-title>
                    <v-list-item-subtitle>{{
                      user_info.email
                    }}</v-list-item-subtitle>
                  </v-list-item>
                  <!-- Add more basic user information as needed -->
                </v-list>
              </v-card-text>
            </v-card>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <Subscription></Subscription>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card>
            <v-card-title>Paid invoices</v-card-title>
            <v-skeleton-loader
              v-if="ui.loading_invoice_list"
              type="table-tbody"
            ></v-skeleton-loader>
            <v-card-text v-if="!ui.loading_invoice_list">
              <v-table density="compact">
                <thead>
                  <tr>
                    <th class="text-left">Subscription</th>
                    <th class="text-left">Credits</th>
                    <th class="text-left">status</th>
                    <th class="text-left">Start</th>
                    <th class="text-left">End</th>
                    <th class="text-left">Amount</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in user_invoices" :key="item.id">
                    <td class="small-text">
                      {{ item.stripe_user_subscription.id || "processing" }}
                    </td>

                    <td>
                      <v-chip color="green">
                        +
                        {{
                          item.stripe_user_subscription.subscription_plan.credit
                        }}
                      </v-chip>
                    </td>
                    <td>{{ item.stripe_invoice_event_type }}</td>
                    <td>{{ item.stripe_period_start }}</td>
                    <td>{{ item.stripe_period_end }}</td>
                    <td>{{ item.amount }}</td>
                  </tr>
                </tbody>
              </v-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-card>
            <v-card-title>Active subscriptions</v-card-title>
            <v-skeleton-loader
              v-if="ui.loading_user_subscripions"
              type="table-tbody"
            ></v-skeleton-loader>
            <v-card-text v-if="!ui.loading_user_subscripions">
              <v-table>
                <thead>
                  <tr>
                    <th class="text-left">ID</th>
                    <th class="text-left">Credits</th>
                    <th class="text-left">Plan</th>
                    <th class="text-left">Description</th>
                    <th class="text-left">Status</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in user_subscriptions" :key="item.id">
                    <td class="small-text">{{ item.id }}</td>
                    <td>
                      <v-chip color="green">
                        + {{ item.subscription_plan.credit }}
                      </v-chip>
                      / month
                    </td>
                    <td>{{ item.subscription_plan.title }}</td>
                    <td>{{ item.subscription_plan.description }}</td>
                    <td>
                      <v-chip
                        :color="
                          item.stripe_subscription_object.status == 'active'
                            ? 'green'
                            : 'red'
                        "
                      >
                        {{ item.stripe_subscription_object.status }}
                      </v-chip>
                    </td>
                    <td>
                      <v-btn
                        class="small-text"
                        color="red-lighten-1"
                        variant="text"
                        @click="
                          ui.subscription_cancel_dialog.show = true;
                          ui.subscription_cancel_dialog.subscription_id =
                            item.id;
                        "
                        >cancel</v-btn
                      >
                    </td>
                  </tr>
                </tbody>
              </v-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import APIMixin from "@/mixins/APIMixin";
import axios from "axios";
import Subscription from "@/components/Subscription.vue";
import ApplicationBar from "../components/ApplicationBar.vue";

export default {
  name: "product-list",
  props: {
    msg: String,
  },
  data() {
    return {
      user_info: {
        email: "",
      },
      ui: {
        loading_user_subscripions: true,
        loading_invoice_list: true,
        subscription_cancel_dialog: {
          show: false,
          subscription_id: "",
          cancel_my_subscription_textfield: "",
          cancel_my_subscription_button: null,
          loading_cancel_my_subscription_button: false,
        },
      },
      user_subscriptions: [],
      user_invoices: [],
    };
  },
  mixins: [APIMixin],
  created() {
    this.getUserInformation();
    this.getUserSubscription();
    this.getUserInvoices();
  },
  methods: {
    CancelUserSubscription: function () {
      this.ui.subscription_cancel_dialog.loading_cancel_my_subscription_button = true;

      let request_url =
        this.api.user.subscriptions.cancel_subscription +
        this.ui.subscription_cancel_dialog.subscription_id +
        "/";
      axios
        .post(request_url)
        .then((response) => {})
        .catch((error) => {
          console.log(error);
          console.log("error happended");
        })
        .finally(() => {
          this.ui.subscription_cancel_dialog.loading_cancel_my_subscription_button = false;
          this.ui.subscription_cancel_dialog.show = false;
          this.getUserSubscription();
        });
    },
    getUserInformation: function () {
      axios
        .get(this.api.user_info)
        .then((response) => {
          this.user_info = response.data;
        })
        .catch((error) => {
          console.log(error);
          console.log("error happended");
        });
    },
    getUserSubscription: function () {
      this.ui.loading_user_subscripions = true;
      axios
        .get(this.api.user.subscriptions.user_subscriptions)
        .then((response) => {
          this.user_subscriptions = response.data.reverse();
        })
        .catch((error) => {
          console.log(error);
          console.log("error happended");
        })
        .finally(() => {
          this.ui.loading_user_subscripions = false;
        });
    },
    getUserInvoices: function () {
      this.ui.loading_invoice_list = true;
      axios
        .get(this.api.user.subscriptions.invoices)
        .then((response) => {
          this.user_invoices = response.data.reverse();
        })
        .catch((error) => {
          console.log(error);
          console.log("error happended");
        })
        .finally(() => {
          this.ui.loading_invoice_list = false;
        });
    },
  },
  components: { Subscription, ApplicationBar },
};
</script>
<style scoped>
.small-text {
  font-size: 11px; /* Define your desired font size here */
}
</style>
