/* eslint-disable no-console */

import { createApp } from "vue";
import App from "./App.vue";
import axios from "axios";
import "vuetify/styles";
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import "vuetify/dist/vuetify.min.css";
// import 'material-design-icons-iconfont/dist/material-design-icons.css'
import "@mdi/font/css/materialdesignicons.css";
import { aliases, mdi } from "vuetify/lib/iconsets/mdi";
import { fa } from "vuetify/iconsets/fa";
import "./sass/variables.scss";
import * as Sentry from "@sentry/vue";

import router from "./router";

import authenticationMixin from "./mixins/authenticationMixin";
import APIMixin from "./mixins/APIMixin";
// import Vuetify from "vuetify";

// Vue.config.productionTip = false

const vuetify = createVuetify({
  components,
  directives,
  defaults: {
    global: {
      ripple: true,
    },
  },
  icons: {
    defaultSet: "mdi",
    aliases,
    sets: {
      mdi,
      fa,
    },
  },
  theme: {
    defaultTheme: "light",
    themes: {
      light: {
        dark: false,
        colors: {
          // your color palette
        },
      },
    },
    options: {
      customProperties: true,
    },
  },
  treeShake: true,
});

axios.interceptors.request.use(
  (config) => {
    const token = authenticationMixin.methods.getJWTAccessToken();
    if (token) {
      config.headers.Authorization = "Bearer ".concat(token);
    }
    // console.log("this is config", config);
    if (!config.headers["Content-Type"]) {
      config.headers["Content-Type"] = "application/json";
    }
    // console.log(config.headers);
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  // remember to make this async as the store action will
  // need to be awaited
  async function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    const originalRequest = error.config;
    if (
      error.response.status === 401 &&
      originalRequest.url.includes("token/refresh")
    ) {
      authenticationMixin.methods.clearJWTTokens();
      router.push("/login");
      return Promise.reject(error);
    } else if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      // Send refresh toke request

      let data = JSON.stringify({
        refresh: authenticationMixin.methods.getJWTRefreshToken(),
      });
      console.log(data);
      const refresh_config = {
        headers: {
          Authorization: "",
        },
      };
      return axios
        .post(APIMixin.data().api.refresh_jwt_token, data, refresh_config)
        .then((response) => {
          console.log();
          console.log(response);
          authenticationMixin.methods.storeJWTAccessToken(response.data.access);
          return axios(originalRequest);
        })
        .catch((error) => {
          console.log(error);
          console.log("error happended, in refresh token");
          authenticationMixin.methods.logout();
          router.push("/login");
          return Promise.reject(error);
        });

      //
    }
    return Promise.reject(error);
  }
);

const app = createApp(App);
Sentry.init({
  app,
  dsn: "https://22c58b4141225bcbb179bb9f278a0d89@sentry.cronium.io/5",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

app.use(router).use(vuetify).mount("#app");
