<template>
  <navigation-drawer></navigation-drawer>
  <v-spacer></v-spacer>

  <p>This is the home page</p>

  <v-btn color="primary" v-on:click.prevent="checkHealth"> User</v-btn>
</template>

<script>
import APIMixin from "@/mixins/APIMixin";
import axios from "axios";
import Sidebar from "@/components/Sidebar.vue";
import NavigationDrawer from "../components/NavigationDrawer.vue";

export default {
  name: "home-page",
  props: {
    msg: String,
  },
  mounted() {
    this.$router.push("/studio");
  },
  mixins: [APIMixin],
  methods: {
    checkHealth: function () {
      axios
        .get(this.api.user_info)
        .then((response) => {
          console.log("response recieved");
          console.log(response.status);
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
          console.log("error happended");
        });
    },
  },
  components: { Sidebar, NavigationDrawer },
};
</script>
