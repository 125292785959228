import { createWebHistory, createRouter } from "vue-router";
import LoginPage from "@/views/user/LoginPage.vue";
import LogoutPage from "@/views/user/LogoutPage.vue";
import RegistrationPage from "@/views/user/RegistrationPage.vue";
import RegistrationSuccessPage from "@/views/RegistrationSuccessPage.vue";
import HomePage from "@/views/HomePage.vue";
import CanvasPage from "@/views/CanvasPage.vue";
import ProductList from "@/views/ProductList";
import CreateProduct from "@/views/CreateProduct";
import StudioPage from "@/views/studio/StudioPage.vue";
import StudioPageTest from "@/views/studio/StudioPage-test.vue";
import ComponentTest from "@/views/studio/ComponentTest.vue";
import EmailVerificationSuccessPage from "@/views/EmailVerificationSuccessPage";
import NotFounde404 from "@/views/NotFounde404";
import UserProfile from "@/views/UserProfile";
import StudioInitPage from "@/views/studio/StudioInitPage.vue";
import EmailConfirmationPage from "@/views/user/EmailConfirmationPage.vue";

const routes = [
  {
    path: "",
    // name: "Login Page",
    // component: LoginPage,
    redirect: "/login",
  },
  {
    path: "/login",
    name: "Login Page",
    component: LoginPage,
  },
  {
    path: "/logout",
    name: "logout",
    component: LogoutPage,
  },
  // {
  //   path: "/register",
  //   name: "User Registration",
  //   component: RegistrationPage,
  // },
  {
    path: "/registration_success",
    name: "Registration Success",
    component: RegistrationSuccessPage,
  },
  {
    path: "/email-confirmed/:key",
    name: "email-confirmated",
    component: EmailConfirmationPage,
  },
  {
    path: "/email-verification-successful",
    name: "Email Verification Success",
    component: EmailVerificationSuccessPage,
  },
  {
    path: "/home",
    name: "Home Page",
    component: HomePage,
  },
  {
    path: "/canvas",
    name: "Canvas Page",
    component: CanvasPage,
  },
  {
    path: "/products",
    name: "Product List",
    component: ProductList,
  },
  {
    path: "/product/create",
    name: "Create Product",
    component: CreateProduct,
  },
  {
    path: "/studio/:garment_id",
    name: "Studio",
    component: StudioPage,
    params: true,
  },
  {
    path: "/studio-test/:garment_id",
    name: "Studio Test",
    component: StudioPageTest,
    params: true,
  },
  {
    path: "/component-test",
    name: "Component Test",
    component: ComponentTest,
    params: true,
  },
  {
    path: "/studio",
    name: "StudioInitPage",
    component: StudioInitPage,
    params: true,
  },

  {
    path: "/:notFound",
    component: NotFounde404,
  },
  {
    path: "/profile",
    name: "User Profile",
    component: UserProfile,
  },
  // {
  //     path: "*",
  //     // name: "Product Studio",
  //     component: LoginPage,
  //     // params: true

  // },
  // {
  //     path: "/about",
  //     name: "About",
  //     component: About,
  // },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
