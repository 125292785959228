<template>
  <v-toolbar app color="primary">
    <v-btn icon @click="clearCanvas">
      <v-icon>mdi-eraser</v-icon>
    </v-btn>
    <v-btn icon @click="exportCanvas">
      <v-icon>mdi-download</v-icon>
    </v-btn>
    <v-btn-toggle v-model="brushMode" class="ml-2">
      <v-btn :value="BrushModes.PENCIL" icon>
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
      <v-btn :value="BrushModes.ERASER" icon>
        <v-icon>mdi-eraser-variant</v-icon>
      </v-btn>
    </v-btn-toggle>
    <v-slider
      v-model="brushSize"
      :max="20"
      :thumb-label="brushSize"
      thumb-size="22"
      thumb-color="primary"
      class="ml-4"
    ></v-slider>
  </v-toolbar>

  <v-main>
    <canvas ref="canvas"></canvas>
  </v-main>
</template>

<script>
import { fabric } from "fabric";

export default {
  data() {
    return {
      canvas: null,
      brushMode: "",
      brushSize: 5,
      BrushModes: {
        PENCIL: "pencil",
        ERASER: "eraser",
      },
    };
  },
  mounted() {
    this.initializeCanvas();
  },
  methods: {
    initializeCanvas() {
      const canvasElement = this.$refs.canvas;
      const { width, height } = canvasElement.getBoundingClientRect();

      this.canvas = new fabric.Canvas(canvasElement, {
        width,
        height,
        selection: false,
        isDrawingMode: true,
        backgroundColor: "#ffffff",
      });
      this.canvas.freeDrawingBrush.width = this.brushSize;
      this.canvas.freeDrawingBrush.color = "black";

      this.canvas.on("after:render", () => {
        this.canvas.contextContainer.strokeStyle =
          this.brushMode === this.BrushModes.ERASER ? "white" : "black";
      });
    },
    clearCanvas() {
      this.canvas.clear();
    },
    exportCanvas() {
      const dataURL = this.canvas.toDataURL({
        format: "png",
        multiplier: 2,
      });

      const link = document.createElement("a");
      link.href = dataURL;
      link.download = "canvas.png";
      link.click();
    },
  },
  watch: {
    brushMode() {
      this.canvas.isDrawingMode = this.brushMode === this.BrushModes.PENCIL;
      this.canvas.freeDrawingBrush.color =
        this.brushMode === this.BrushModes.ERASER ? "white" : "black";
    },
    brushSize() {
      this.canvas.freeDrawingBrush.width = this.brushSize;
    },
  },
};
</script>

<style>
.canvas-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
canvas {
  border: 1px solid #e0e0e0;
}
</style>
