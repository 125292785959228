<template>
  <div style="position: fixed; top: 12px; right: 32px; z-index: 1000">
    <v-btn icon color="indigo-darken-4">
      <v-icon>mdi-account-circle</v-icon>
      <v-menu activator="parent">
        <v-list>
          <v-list-item>
            <v-list-item-title>{{ user_info.email }}</v-list-item-title>
            <v-list-item-subtitle
              >credits: {{ user_info.credit }}</v-list-item-subtitle
            >
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item
            v-for="(link, index) in links"
            :key="index"
            @click="goToPage(link[2])"
          >
            <template v-slot:prepend>
              <v-icon :icon="link[0]"></v-icon>
            </template>

            <v-list-item-title>{{ link[1] }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-btn>
  </div>
</template>

<script>
// import APIMixin from "@/mixins/APIMixin"
import axios from "axios";
import router from "@/router";
import APIMixin from "@/mixins/APIMixin";
import authenticationMixin from "@/mixins/authenticationMixin";

export default {
  name: "ApplicationBar",
  props: {
    msg: String,
  },
  mixins: [APIMixin, authenticationMixin],

  data: () => ({
    drawer: false,
    user_profile_menu: false,
    links: [
      ["mdi-home", "Home", "/home"],
      ["mdi-account-cash", "Profile & Billing", "/profile"],
      ["mdi-logout", "Logout", "/logout"],
    ],
    user_info: {},
    menu_model: false,
    rail: true,
  }),
  created() {
    this.getUserInformation();
  },
  methods: {
    goToPage: function (destination) {
      router.push(destination);
    },
    logoutUser: function () {
      this.logout();
      router.push("/login");
    },
    getUserInformation: function () {
      axios
        .get(this.api.user_info)
        .then((response) => {
          this.user_info = response.data;
        })
        .catch((error) => {
          console.log(error);
          console.log("error happended");
        });
    },
  },
};
</script>
