<template>
  <v-app id="inspire">
    <v-main>
      <v-container class="fill-height" fluid>
        <v-row align="center" justify="center">
          <v-col cols="6" align="center" justify="center">
            <v-card
              style="background-color: rgba(179, 170, 170, 0.8)"
              rounded="xl"
            >
              <v-card-text>
                <span>Sending you to the studio page...</span>
              </v-card-text>
              <v-card-text>
                <v-progress-circular
                  indeterminate
                  color="blue"
                ></v-progress-circular>
              </v-card-text>
              <!-- <v-card-actions> -->
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>
  
  
  
  <script>
/* eslint-disable no-console */

import AuthMixins from "@/mixins/authenticationMixin";
import APIMixin from "@/mixins/APIMixin";
import FormMixin from "@/mixins/FormMixin";
import router from "@/router";

export default {
  name: "login-page",
  data() {
    return {
      user: { email: "", password: "" },
      login_button_loading: false,
      form_message: [],
    };
  },
  components: {},

  mixins: [AuthMixins, APIMixin, FormMixin],
  created() {
    this.logoutUser();
  },

  methods: {
    logoutUser: function () {
      this.logout();
      router.push("/login");
    },
  },
};
</script>
  

<style scoped>
.registration-page {
  background-image: url("../../assets/login-page.jpg");
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>