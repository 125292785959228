<template>
  <v-app id="inspire">
    <v-main>
      <v-container class="fill-height" fluid>
        <v-row align="center" justify="center">
          <v-col cols="6" align="center" justify="center">
            <v-card
              style="background-color: rgba(179, 170, 170, 0.8)"
              rounded="xl"
            >
              <v-card-text>
                <span>Sending you to the studio page...</span>
              </v-card-text>
              <v-card-text>
                <v-progress-circular
                  indeterminate
                  color="blue"
                ></v-progress-circular>
              </v-card-text>
              <!-- <v-card-actions> -->
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>
  
  <script>
/* eslint-disable no-console */

import AuthMixins from "@/mixins/authenticationMixin";
import APIMixin from "@/mixins/APIMixin";
import axios from "axios";
// import MessageShow from "./MessageShow";

export default {
  name: "login-page",
  data() {
    return {
      user: { email: "", password: "" },
    };
  },
  components: {},

  mixins: [AuthMixins, APIMixin],
  created() {
    this.getUserInformation();
    if (!this.isAuthenticated) {
      this.goToLogin();
    }
    this.loadGarmentList();
  },

  methods: {
    goToLogin() {
      this.$router.push("/login");
    },
    getUserInformation: function () {
      axios
        .get(this.api.user_info)
        .then((response) => {
          this.user_info = response.data;
        })
        .catch((error) => {
          console.log(error);
          console.log("error happended");
        });
    },

    loadGarmentList: function () {
      let request_url = this.api.studio.garments;

      axios
        .get(request_url)
        .then((response) => {
          const last_garment_id = response.data[response.data.length - 1].id;
          this.$router.push("/studio/" + last_garment_id);
        })
        .catch((error) => {
          console.log(error);
          console.log("error happended");
        });
    },
  },
};
</script>
  

<style scoped>
.registration-page {
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>