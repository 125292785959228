<template>
  <v-app id="inspire">
    <v-main>
      <v-container class="fill-height registration-page" fluid>
        <v-row align="center" justify="center">
          <v-col cols="12" sm="8" md="6" align="center" justify="center">
            <v-card
              style="background-color: rgba(207, 211, 205, 0.85)"
              rounded="xl"
            >
              <v-card-text>
                <v-icon
                  class="mb-6"
                  color="success"
                  icon="mdi-check-circle-outline"
                  size="128"
                ></v-icon>
                <div class="text-h5" color="white">
                  Please wait, confirming your email.
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>
  
  
  
  <script>
/* eslint-disable no-console */

import AuthMixins from "@/mixins/authenticationMixin";
import APIMixin from "@/mixins/APIMixin";
import axios from "axios";
import { useRoute } from "vue-router";

export default {
  name: "login-page",
  data() {
    return {
      user: { username: "", password: "", password2: "" },
    };
  },
  components: {},

  mixins: [AuthMixins, APIMixin],
  created() {
    this.sendEmailVerification();
    console.log(this.isAuthenticated());
    if (this.isAuthenticated()) {
      this.$router.push("/home");
    }
  },

  methods: {
    sendEmailVerification: function () {
      const route = useRoute();

      let data = JSON.stringify({
        key: route.params.key,
      });
      console.log(data);
      axios
        .post(this.api.user.confirm_email, data)
        .then((response) => {})
        .catch((error) => {
          console.log(error);
          console.log("error happended");
          this.logout();
        })
        .finally(() => {
          this.$router.push("/login");
        });
    },
  },
};
</script>
  
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.registration-page {
  background-image: url("../../assets/registration-page.jpg");
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
  