<template>
  <navigation-drawer></navigation-drawer>
  <v-main>
    <v-sheet class="mx-4 mt-4">
      <v-row justify="d-flex justify-center align-center">
        <v-col align-self="center" cols="7" sm="6" md="3" lg="3">
          <v-btn
            block
            color="green-darken-2"
            dark
            :loading="image_uploader.loading"
            @click="ImageUploaderHandleFileImport"
            prepend-icon="mdi-plus-circle"
            class="text-none"
            size="large"
            variant="flat"
            :ripple="false"
          >
            Upload Your Image
          </v-btn>
          <input
            ref="image_uploader"
            class="d-none"
            type="file"
            @change="ImageUploaderOnFileChanged"
          />
          <!-- <v-btn color="purple" @click.prevent="goToCreateProduct()"
            ><v-icon icon="mdi-plus-circle"></v-icon> Create Product</v-btn
          > -->
        </v-col>
      </v-row>
      <v-row>
        <v-col
          v-for="product in products"
          :key="product.id"
          cols="12"
          sm="6"
          md="4"
          lg="3"
        >
          <v-card
            variant="flat"
            color="grey-lighten-5"
            @click="goToProductStudio(product)"
          >
            <v-img :src="product.image" contain :aspect-ratio="1" cover></v-img>
          </v-card>
        </v-col>
      </v-row>
    </v-sheet>
  </v-main>
</template>

<script>
import APIMixin from "@/mixins/APIMixin";
import axios from "axios";
import Sidebar from "@/components/Sidebar.vue";
import NavigationDrawer from "../components/NavigationDrawer.vue";
import router from "@/router";

export default {
  name: "product-list",
  props: {
    msg: String,
  },
  data() {
    return {
      headers: ["Image", "Title", "Category"],
      products: [],
      image_uploader: {
        loading: false,
        button: null,
        selected_file: null,
      },
    };
  },
  mixins: [APIMixin],
  created() {
    this.LoadImageList();
  },
  methods: {
    checkHealth: function () {
      axios
        .get(this.api.user_info)
        .then((response) => {
          console.log("response recieved");
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
          console.log("error happended");
        });
    },
    goToCreateProduct: function () {
      router.push("/product/create");
    },
    goToProductStudio: function (product) {
      router.push("/product-studio/" + product.id);
      // console.log(product);
    },
    LoadImageList: function () {
      axios
        .get(this.api.image_list)
        .then((response) => {
          console.log(response);
          this.products = response.data.reverse();
        })
        .catch((error) => {
          console.log(error);
          console.log("error happended");
        });
    },
    ImageUploaderHandleFileImport() {
      this.isSelecting = true;

      // After obtaining the focus when closing the FilePicker, return the button state to normal
      window.addEventListener(
        "focus",
        () => {
          // this.image_uploader.loading = false;
        },
        { once: true }
      );

      // Trigger click on the FileInput
      this.$refs.image_uploader.click();
    },
    ImageUploaderOnFileChanged(e) {
      this.image_uploader.selected_file = e.target.files[0];

      if (this.image_uploader.selected_file) {
        this.image_uploader.loading = true;
        let formData = new FormData();
        // console.log(this.tattoo_gallery_image_uploader.selectedFile);

        formData.append("image", this.image_uploader.selected_file);
        console.log(formData);
        axios
          .post(this.api.product_create, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            console.log("Success!");
            console.log({ response });
            this.image_uploader.loading = false;
            this.LoadImageList();
          })
          .catch((error) => {
            this.image_uploader.loading = false;
            console.log({ error });
          });
      } else {
        console.log("there are no files.");
      }
    },
  },
  components: { NavigationDrawer },
};
</script>
