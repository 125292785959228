/* eslint-disable no-console */

import axios from "axios";
import APIMixin from "../mixins/APIMixin";
import VueJwtDecode from 'vue-jwt-decode'

const JWT_ACCESS_TOKEN = "JWT_ACCESS_TOKEN";
const JWT_ACCESS_TOKEN_EXPIRATION = "JWT_ACCESS_TOKEN_EXPIRATION";

const JWT_REFRESH_TOKEN = "JWT_REFRESH_TOKEN";
const JWT_REFRESH_TOKEN_EXPIRATION = "JWT_REFRESH_TOKEN_EXPIRATION";


const USER_ACCESS_TOKEN_Key = "user_access_token";
const IS_LOGGED_IN_KEY = "user_is_logged_in_token";

export default {
  methods: {
    storeJWTAccessToken: function (jwt_access_token) {
      localStorage.setItem(JWT_ACCESS_TOKEN, jwt_access_token);
      localStorage.setItem(JWT_ACCESS_TOKEN_EXPIRATION, VueJwtDecode.decode(jwt_access_token).exp);
    },
    getJWTAccessToken: function () {
      return localStorage.getItem(JWT_ACCESS_TOKEN);
    },
    getJWTAccessTokenExpiration: function () {
      return localStorage.getItem(JWT_ACCESS_TOKEN_EXPIRATION);
    },

    storeJWTRefreshToken: function (jwt_refresh_token) {
      localStorage.setItem(JWT_REFRESH_TOKEN, jwt_refresh_token);
      localStorage.setItem(JWT_REFRESH_TOKEN_EXPIRATION, VueJwtDecode.decode(jwt_refresh_token).exp);
    },
    getJWTRefreshToken: function () {
      return localStorage.getItem(JWT_REFRESH_TOKEN);
    },
    getJWTRefreshTokenExpiration: function () {
      return localStorage.getItem(JWT_REFRESH_TOKEN_EXPIRATION);
    },

    clearJWTTokens: function () {
      localStorage.removeItem(JWT_ACCESS_TOKEN)
      localStorage.removeItem(JWT_ACCESS_TOKEN_EXPIRATION)
      localStorage.removeItem(JWT_REFRESH_TOKEN)
      localStorage.removeItem(JWT_REFRESH_TOKEN_EXPIRATION)
    },

    logout: function () {
      this.clearJWTTokens()
    },
    isAuthenticated: function () {
      if (this.getJWTAccessToken()) {
        return true
      } else {
        return false
      }
    }
  },
};
