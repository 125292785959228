/* eslint-disable no-console */


export default {
  data() { },
  methods: {
    formNotEmptyRule: function (value) {
      if (value) return true

      return 'Should not be Empty'
    },
    formGenerateErrorMessage: function (error_response) {
      var error_string = "";
      for (let error_field in error_response) {
        error_string += error_response[error_field]
        error_string += "</br>"
      }
      return error_string
    },
  },
};
