<template>
  <v-app id="inspire">
    <v-main>
      <v-container class="fill-height registration-page" fluid>
        <v-row align="center" justify="center">
          <v-col cols="6" align="center" justify="center">
            <v-card
              style="background-color: rgba(179, 170, 170, 0.8)"
              rounded="xl"
            >
              <v-toolbar color="blue-lighten-5" flat>
                <v-toolbar-title>404 Page Not Found</v-toolbar-title>
                <v-spacer />
              </v-toolbar>
              <v-card-text>
                <v-btn color="success" block v-on:click.prevent="goToLogin"
                  >Go to Login Page</v-btn
                >
              </v-card-text>
              <!-- <v-card-actions> -->
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>
  
  
  
  <script>
/* eslint-disable no-console */

import AuthMixins from "@/mixins/authenticationMixin";
import APIMixin from "@/mixins/APIMixin";
// import axios from "axios";
// import MessageShow from "./MessageShow";

export default {
  name: "login-page",
  data() {
    return {
      user: { email: "", password: "" },
    };
  },
  components: {},

  mixins: [AuthMixins, APIMixin],
  created() {
    // console.log(this.isAuthenticated());
    // console.log("env-variables", process.env);
    // console.log("env-variables", import.meta.env);
    // if (this.isAuthenticated()) {
    //   this.$router.push("/home");
    // }
  },

  methods: {
    goToLogin() {
      this.$router.push("/login");
    },
  },
};
</script>
  

<style scoped>
.registration-page {
  background-image: url("../assets/login-page.jpg");
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>