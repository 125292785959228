<template>
  <v-app>
    <v-main>
      <v-container>
        <v-row>
          <v-col>
            <v-toolbar flat>
              <v-btn-toggle vertical v-model="toggle1">
                <v-btn depressed height="128">
                  <v-icon left>mdi-home</v-icon> Button 1A
                </v-btn>
                <v-btn depressed height="128">
                  <v-icon left>mdi-account</v-icon> Button 1B
                </v-btn>
              </v-btn-toggle>
            </v-toolbar>
            <v-toolbar flat>
              <v-btn-toggle vertical v-model="toggle2">
                <v-btn depressed height="128">
                  <v-icon left>mdi-settings</v-icon> Button 2A
                </v-btn>
                <v-btn depressed height="128">
                  <v-icon left>mdi-information</v-icon> Button 2B
                </v-btn>
              </v-btn-toggle>
            </v-toolbar>
            <v-toolbar flat>
              <v-btn-toggle vertical v-model="toggle3">
                <v-btn depressed height="128">
                  <v-icon left>mdi-email</v-icon> Button 3A
                </v-btn>
                <v-btn depressed height="128">
                  <v-icon left>mdi-phone</v-icon> Button 3B
                </v-btn>
              </v-btn-toggle>
            </v-toolbar>
            <v-toolbar flat>
              <v-btn-toggle vertical v-model="toggle4">
                <v-btn depressed height="128">
                  <v-icon left>mdi-map</v-icon> Button 4A
                </v-btn>
                <v-btn depressed height="128">
                  <v-icon left>mdi-calendar</v-icon> Button 4B
                </v-btn>
              </v-btn-toggle>
            </v-toolbar>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  data: () => ({
    toggle1: 0,
    toggle2: 0,
    toggle3: 0,
    toggle4: 0,
  }),
};
</script>

<style>
</style>
