<template>
  <v-app id="inspire">
    <v-main>
      <v-container class="fill-height registration-page" fluid>
        <v-row align="center" justify="center">
          <v-col cols="6" align="center" justify="center">
            <v-card
              style="background-color: rgba(207, 211, 205, 0.85)"
              rounded="xl"
            >
              <v-card-text>
                <v-icon
                  class="mb-6"
                  color="success"
                  icon="mdi-check-circle-outline"
                  size="128"
                ></v-icon>
                <div class="text-body-1" color="white">
                  You e-mail verification process was successfull. Go to
                  <v-card-text>
                    <v-btn color="success" block v-on:click.prevent="goToLogin"
                      >Go to Login Page</v-btn
                    >
                  </v-card-text>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>
  
  
  
  <script>
/* eslint-disable no-console */

import AuthMixins from "@/mixins/authenticationMixin";
import APIMixin from "@/mixins/APIMixin";
import axios from "axios";
// import MessageShow from "./MessageShow";

export default {
  name: "login-page",
  data() {
    return {
      user: { username: "", password: "", password2: "" },
    };
  },
  components: {},

  mixins: [AuthMixins, APIMixin],
  created() {
    console.log(this.isAuthenticated());
    if (this.isAuthenticated()) {
      this.$router.push("/home");
    }
  },

  methods: {
    goToLogin() {
      this.$router.push("/login");
    },
    sendLoginRequest: function () {
      console.log(this.api.register);
      let data = JSON.stringify({
        username: this.user.username,
        password: this.user.password,
        password2: this.user.password2,
      });
      console.log(data);
      axios
        .post(this.api.register, data)
        .then((response) => {
          console.log("response recieved");
          console.log(response);

          this.storeJWTAccessToken(response.data.access);
          this.storeJWTRefreshToken(response.data.refresh);
          this.$router.push("/registration_success");
        })
        .catch((error) => {
          console.log(error);
          console.log("error happended");
          this.logout();
          // this.$refs.MessageShow.showMessage(
          //   '',
          //   "error"
          // );
          // TO DO:
          // show an error message when login is not successful
        });
    },
  },
};
</script>
  
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.registration-page {
  background-image: url("../assets/registration-page.jpg");
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
  