<template>
  <v-app id="inspire">
    <v-main>
      <div class="registration-page">
        <v-row no-gutters style="height: 100vh">
          <v-col sm="12" lg="6" align="center" justify="center">
            <v-row
              class="d-flex justify-center align-center"
              style="height: 100vh"
            >
              <v-col cols="6">
                <v-card style="background-color: rgba(179, 170, 170, 0.4)">
                  <v-toolbar color="#0D3C74" flat>
                    <v-toolbar-title class="font-weight-bold text-center"
                      >Login to Dashboard</v-toolbar-title
                    >
                    <v-spacer />
                  </v-toolbar>
                  <v-card-text>
                    <v-form
                      validate-on="submit lazy"
                      @submit.prevent="sendLoginRequest"
                    >
                      <v-text-field
                        type="text"
                        v-model="user.email"
                        variant="outlined"
                        label="Email"
                        :rules="[this.formNotEmptyRule]"
                        required
                      />
                      <v-text-field
                        type="password"
                        v-model="user.password"
                        variant="outlined"
                        label="Password"
                        :rules="[this.formNotEmptyRule]"
                        required
                      />
                      <v-btn
                        color="#C5F96F"
                        type="submit"
                        :loading="login_button_loading"
                        block
                        variant="outlined"
                        >Login</v-btn
                      >
                    </v-form>
                  </v-card-text>
                  <v-card-text>
                    <v-alert
                      v-if="Object.keys(form_message).length > 0"
                      type="warning"
                      title="Fix your inputs"
                      variant="tonal"
                      close-label="Close Alert"
                      color="orange"
                    >
                      <div
                        v-for="(value, key) in form_message"
                        :key="key"
                        class="text-left"
                      >
                        {{ key }}: {{ value.join(" ") }}
                      </div>
                    </v-alert>
                  </v-card-text>
                  <!-- <v-card-actions> -->
                  <v-spacer />
                  <v-card-text>
                    <v-row>
                      <v-col cols="12">
                        <v-btn
                          color="light-blue-darken-4"
                          @click="goToWaitingList()"
                          block
                          flat
                          >Register for the Waiting List</v-btn
                        >
                      </v-col>
                      <!-- <v-col cols="12">
                        <v-btn variant="outlined" block color="grey-lighten-3"
                          >Reset Password</v-btn
                        >
                      </v-col> -->
                    </v-row>
                  </v-card-text>
                  <!-- </v-card-subtitle> -->

                  <!-- </v-card-actions> -->
                </v-card>
              </v-col>
            </v-row>
          </v-col>
          <v-col
            cols="12"
            md="6"
            class="d-none d-md-flex position-relative overflow-hidden"
          >
            <div class="scrolling-image"></div>
            <div
              class="slogan-overlay d-flex flex-column justify-center align-center"
            >
              <div class="text-h1 font-weight-bold white--text text-center">
                Shimmers
              </div>
              <div class="text-h4 white--text text-center mt-4">
                Experience The Future Of Fashion Photography
              </div>
            </div>
          </v-col>
        </v-row>
        <!-- </v-container> -->
      </div>
    </v-main>
  </v-app>
</template>
  
  
  
  <script>
/* eslint-disable no-console */

import AuthMixins from "@/mixins/authenticationMixin";
import APIMixin from "@/mixins/APIMixin";
import FormMixin from "@/mixins/FormMixin";
import axios from "axios";
// import MessageShow from "./MessageShow";

export default {
  name: "login-page",
  data() {
    return {
      user: { email: "", password: "" },
      login_button_loading: false,
      form_message: [],
    };
  },
  components: {},

  mixins: [AuthMixins, APIMixin, FormMixin],
  created() {
    console.log(this.isAuthenticated());
    console.log("env-variables", process.env);
    console.log("env-variables", import.meta.env);

    if (this.isAuthenticated()) {
      this.$router.push("/home");
    }
  },

  methods: {
    goToWaitingList() {
      window.location.href = "https://shimmers.ai/waiting-list";
    },
    goToRegister() {
      this.$router.push("/register");
    },
    sendLoginRequest: function () {
      this.login_button_loading = true;
      let data = JSON.stringify({
        email: this.user.email.trim().toLowerCase(),
        password: this.user.password,
      });
      console.log(data);
      axios
        .post(this.api.user.login, data)
        .then((response) => {
          console.log("response recieved");
          console.log(response);

          this.storeJWTAccessToken(response.data.access);
          this.storeJWTRefreshToken(response.data.refresh);
          this.$router.push("/studio");
        })
        .catch((error) => {
          this.form_message = error.response.data;

          console.log(error);
          console.log("error happended");
          this.logout();
        })
        .finally(() => {
          this.login_button_loading = false;
        });
    },
  },
};
</script>
  

<style scoped>
.registration-page {
  /* background-image: url("../../assets/login-page.jpg"); */
  background-color: #2b3e57;
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.scrolling-image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: url("@/assets/photoshoot-tile.png"); /* Replace with your tall image URL */
  background-size: cover;
  background-position: center;
  background-repeat: repeat-y;

  animation: scrollBackground 400s linear infinite;
}

@keyframes scrollBackground {
  from {
    background-position: 0 -1000%, 0 0%;
  }
  to {
    background-position: 0 1000%, 0 0;
  }
}

.slogan-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(233, 233, 233, 0.5); /* Semi-transparent overlay */
  z-index: 1;
}
</style>