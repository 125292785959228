<template>
  <v-card color="indigo-lighten-2">
    <v-card-title> Subscription </v-card-title>

    <v-card-text>
      <v-row v-if="loading_subscription_plans">
        <v-col cols="4" v-for="i in [1, 2, 3]" :key="i">
          <v-skeleton-loader type="article"></v-skeleton-loader>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          sm="12"
          lg="4"
          v-for="subscription in subscription_plans"
          :key="subscription.id"
        >
          <!-- <v-card :color="subscription.extra_config.color"> -->
          <!-- <v-card :style="subscription.extra_config.style"> -->
          <v-card color="linear-gradient(to bottom, #FF6B6B, #4ECDC4)">
            <v-card-title> {{ subscription.title }} </v-card-title>
            <v-card-subtitle>
              {{ subscription.price }} {{ subscription.currency }}
            </v-card-subtitle>
            <v-card-text>
              <v-list>
                <v-list-item>
                  <v-list-item-title>
                    {{ subscription.description }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-card-text>
            <v-card-text>
              <v-btn
                color="black"
                @click="goToSubscriptionPlanCheckout(subscription)"
                :loading="loading_subscription_checkout_request"
                block
                flat
                >Subscribe {{ subscription.name }} for {{ subscription.price }}
                {{ subscription.currency }}/month</v-btn
              >
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
  <v-divider></v-divider>
</template>

<script>
import APIMixin from "@/mixins/APIMixin";
import axios from "axios";
import router from "@/router";

export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
  mixins: [APIMixin],
  mounted() {
    this.loadSubscriptionPlans();
  },
  data() {
    return {
      loading_subscription_plans: false,
      loading_subscription_checkout_request: false,
      subscription_plans: [],
    };
  },
  methods: {
    checkHealth: function () {
      axios
        .get(this.api.user_info)
        .then((response) => {
          console.log("response recieved");
          console.log(response);
          console.log(response.status);
        })
        .catch((error) => {
          console.log(error);
          console.log("error happended");
        });
    },
    loadSubscriptionPlans: function () {
      this.loading_subscription_plans = true;
      axios
        .get(this.api.user.subscriptions.plans)
        .then((response) => {
          console.log("response recieved");
          console.log(response);
          // console.log(response.status);
          this.subscription_plans = response.data;
        })
        .catch((error) => {
          console.log(error);
          console.log("error happended");
        })
        .finally(() => {
          this.loading_subscription_plans = false;
        });
    },
    goToSubscriptionPlanCheckout: function (subscription) {
      this.loading_subscription_checkout_request = true;

      let data = JSON.stringify({
        subscription_plan: subscription.id,
      });
      axios
        .post(this.api.user.subscriptions.subscription_checkout_request, data)
        .then((response) => {
          console.log("response recieved successfully");
          this.redirectToCheckoutURL(response.data.psp_payment_url);
        })
        .catch((error) => {
          this.requestErrorHandler(error);
        })
        .finally(() => {
          this.loading_subscription_checkout_request = false;
        });
    },
    redirectToCheckoutURL: function (subscription_checkout_url) {
      window.location.href = subscription_checkout_url;
    },
  },
};
</script>

